import React, { useEffect } from 'react';

function HandlePostMessages(): JSX.Element {
    useEffect(() => {
        const setupLoginInfo = (message: { origin: string; data: string }): void => {
            // TODO: add whitelist for message.origin
            try {
                console.log('RECEIVED POST MESSAGE IN CVAT');
                console.log(message.data);
                const data = JSON.parse(message.data);
                const {
                    url,
                    headers,
                } = data;

                localStorage.setItem('platformNotiticationShown', 'shown');
                localStorage.setItem('featuresNotiticationShown', 'shown');

                fetch(url, {
                    method: 'GET',
                    headers,
                    credentials: 'include',
                }).then(() => {
                    const successMessage = {
                        status: 'completed-auto-login',
                    };
                    console.log(successMessage);
                    window.parent.postMessage(JSON.stringify(successMessage));
                });
            } catch (error) {
                console.log(`Exception during automatic login attempt for CVAT: ${error}`);
            }
        };
        window.addEventListener('message', setupLoginInfo);

        return () => {
            window.removeEventListener('message', setupLoginInfo);
        };
    }, []);

    return <div>dummy page for eleval control iframe communication</div>;
}

export default HandlePostMessages;
