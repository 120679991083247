// Copyright (C) 2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Icon from '@ant-design/icons';
import Button from 'antd/lib/button';

import { CombinedState } from 'reducers';
import { registerComponentShortcuts } from 'actions/shortcuts-actions';
import CVATTooltip from 'components/common/cvat-tooltip';
import { ShortcutScope } from 'utils/enums';
import { saveAnnotationsAsync } from 'actions/annotation-actions';
import { FinishIcon } from 'icons';

const componentShortcuts = {
    SAVE_JOB: {
        name: 'Save the job',
        description: 'Submit unsaved changes of annotations to the server',
        sequences: ['ctrl+s'],
        scope: ShortcutScope.ANNOTATION_PAGE,
    },
};

registerComponentShortcuts(componentShortcuts);

function SaveAndFinishAnnotationsButton() {
    const dispatch = useDispatch();
    const { isSaving } = useSelector(
        (state: CombinedState) => ({
            isSaving: state.annotation.annotations.saving.uploading,
            keyMap: state.shortcuts.keyMap,
            normKeyMap: state.shortcuts.normalizedKeyMap,
        }),
        shallowEqual,
    );

    function goBackToHome(): void {
        const topLevelHomeUrl = window.top === window.self ? document.URL : document.referrer;
        const topWindow = window.top;
        if (topWindow) {
            topWindow.location.href = topLevelHomeUrl;
        }
    }

    async function saveAndFinish(): Promise<void> {
        dispatch(saveAnnotationsAsync());
        setTimeout(() => {
            goBackToHome();
        }, 1000);
    }

    return (
        <CVATTooltip overlay='Save current changes and go back'>
            <Button
                type='link'
                onClick={isSaving ? undefined : () => saveAndFinish()}
                className={
                    isSaving ? 'cvat-annotation-header-save-button cvat-annotation-disabled-header-button' :
                        'cvat-annotation-header-save-button cvat-annotation-header-button'
                }
            >
                <Icon component={FinishIcon} />
                {isSaving ? 'Saving...' : 'Finish'}
            </Button>
        </CVATTooltip>
    );
}

function SaveAndFinishAnnotationsButtonWrap(): JSX.Element {
    const overrides = useSelector(
        (state: CombinedState) => state.plugins.overridableComponents.annotationPage.header.saveAnnotationButton,
    );

    if (overrides.length) {
        const [Component] = overrides.slice(-1);
        return <Component />;
    }

    return <SaveAndFinishAnnotationsButton />;
}

export default React.memo(SaveAndFinishAnnotationsButtonWrap);
